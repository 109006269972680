import React from 'react';
import styles from './DayCell.module.css'
import '../../animations.css';
import icons from './weatherIcons';

const DayCell = (props) => {
    let icon = "";
    switch(props.condition) {
        case 'Clear':  icon = icons.sunny; break;
        case 'Clouds':  icon = icons.cloudy; break;
        case 'Thunderstorm':  icon = icons.thunderstorm; break;
        case 'Snow':  icon = icons.snowy; break;
        case 'Rain':  icon = icons.rainy; break;
        case 'Drizzle':  icon = icons.drizzle; break;
        case 'Tornado':  icon = icons.tornado; break;
        case 'Mist':  icon = icons.mist; break;
        default: icon = icons.unknownWeather; break;
    }
    const convertedDate = new Date(props.date * 1000).toDateString();
    if (props.loading) {
        return (<h2 className="Loader"></h2>);
    }
    else {
        if (props.error) {
            return ( <div className={styles.DayCell}>
                    <h2>Please enter a valid ZIP code!</h2>
            </div>
            )
        }
        else {
            return (
                
                <div className={styles.DayCell}>
                        <h2>{props.cityName}</h2>
                        <img src={icon} alt={props.description} className="Floating" />
                        <h3>{convertedDate}</h3>
                        <h4>{props.description}</h4>
                        <p className={styles.HighTemp}>{props.temp} °</p>
                        <p className={styles.FeelsLike}>Feels like: {props.feelsLike} °</p>
                    </div>
                    
            )
        }
    }
    
    
    
}

export default DayCell;