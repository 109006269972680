import React, {Component} from 'react';
import styles from './App.module.css';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group';
import './animations.css';

import Button from './components/button/Button';
import DayCell from './components/DayCell/DayCell';

  class App extends Component {
    state = {
      date: "",
    weather: {
      condition: "",
      description: "",
      temp: "",
      feelsLike: "",
    },
    code: "",
    cityName: "",
    jsx: null,
    error: false,
    loading: true
  }

  tempZip;


  
  updateWeather = (zip) => {
    let axiosURL = "http://api.openweathermap.org/data/2.5/weather?zip=" + zip + "&units=imperial&appid=fe4cbd01c2c1ce128a5675021e6c705d";
   
    axios.get(axiosURL)
      .then(res => {
        let tempState = {
          date: "",
          cityName: "",
          weather: {
            condition: "",
            description: "",
            temp: "",
            high: "",
            low: "",
            feelsLike: "",
          },
          code: "",
          error: false
        };
        if (res.request.status == 404) {
          this.setState({error: true, loading: false, seedId: tempState.seedId});
        }
        else {
          tempState.date = res.data.dt;
        tempState.cityName = res.data.name;
        tempState.code = res.data.cod;
        tempState.weather.temp = res.data.main.temp;
        tempState.weather.feelsLike = res.data.main.feels_like;
        tempState.weather.condition = res.data.weather[0].main;
        tempState.weather.description = res.data.weather[0].description;
        this.setState({weather: tempState.weather, cityName: tempState.cityName, code: tempState.code, date: tempState.date, error: false, loading: false});
        }
      }).catch( (error) => {
        console.log(error);
        this.setState({error: true});
      }
        // alert("error!")
        // 
      )
  }

  componentDidMount() {
    this.updateWeather(10001);
  }

  componentWillUnmount() {
    this.setState({loading: true});
  }

 

  zipHandler = () => {
    this.tempZip = this.refs.zipInput.value;
  }

  updateHandler = () => {
    this.updateWeather(this.tempZip);
  }
  
  render () {
    
    return (
      <div className={styles.App}>
        <h1>Current Weather App</h1>
        <p>Enter the five digit ZIP Code of a US city below to see the current weather forecast.</p>
    <input type="text" ref="zipInput" onBlur={this.zipHandler} />
    <Button clicked={this.updateHandler} buttonText="Update" />
    

    <div className={styles.DayContainer} >
    <CSSTransitionGroup
      transitionName="GlitchOut"
      >
        <div key={"DayCell"}>

        <DayCell 
        date={this.state.date}
        feelsLike={this.state.weather.feelsLike}
        temp={this.state.weather.temp}
        condition={this.state.weather.condition}
        description={this.state.weather.description}
        cityName={this.state.cityName}
        error={this.state.error}
        loading={this.state.loading}
        />
        </div>
        </CSSTransitionGroup>
    </div>
    <footer>
        Created by <a href="http://patrickvhessman.com/" rel="noopener noreferrer" target="_blank">Patrick Hessman</a> 👾 Powered by React, Axios, and <a href="https://openweathermap.org/" target="_blank" rel="noopener noreferrer">OpenWeather</a> 👾 Weather Icons by <a href="https://www.deviantart.com/bgbw/art/Pixel-Weather-Icons-208791548" target="_blank" rel="noopener noreferrer">BGBW</a>
      </footer>
      </div>
      
    );
  }
  
}


export default App;
