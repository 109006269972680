import sunny from '../../assets/34.png';
import cloudy from '../../assets/44.png';
import partlyCloudy from '../../assets/28.png';
import tornado from '../../assets/0.png';
import hurricane from '../../assets/1.png';
import rainy from '../../assets/9.png';
import snowy from '../../assets/42.png';
import thunderstorm from '../../assets/4.png';
import drizzle from '../../assets/6.png';
import mist from '../../assets/19.png';
import unknownWeather from '../../assets/na.png';

const icons = {
    sunny: sunny,
    cloudy: cloudy,
    partlyCloudy: partlyCloudy,
    tornado: tornado,
    hurricane: hurricane,
    rainy: rainy,
    snowy: snowy,
    thunderstorm: thunderstorm,
    drizzle: drizzle,
    unknownWeather: unknownWeather,
    mist: mist
};

export default icons;